import {VuexModule, Module, Action, getModule, Mutation} from 'vuex-module-decorators'
import store from '@/store'
import {Provider} from "@/data/classes/provider";
import Vue from "vue";


@Module({ dynamic: true, store, name: 'provider' })
class ProviderModuleClass extends VuexModule implements IProvider {
  public Archived = false;
  public EnableEcommerce = false;
  public EmailConfiguration: IProviderEmailConfiguration;
  public Id: string;
  public Platform: IProviderPlatform;

  @Mutation
  public SetProvider(provider: IProvider){
    this.Archived = provider.Archived;
    this.EnableEcommerce = provider.EnableEcommerce;
    this.EmailConfiguration = provider.EmailConfiguration;
    this.Id = provider.Id;
    this.Platform = provider.Platform;
  }


  @Action
  public async GetProvider() : Promise<string> {
    // Get product list
    const provider = new Provider();

    // Select correct theme
    // @ts-ignore
    const veeewTheme = () => import('@/styles/veeew.scss')
    // @ts-ignore
    const veewarTheme = () => import('@/styles/veewar.scss');

    // Set provider
    const providerId = window.location.host.split(".")[1];
    switch (providerId) {
      case "veeew":
        await veeewTheme();
        return provider.get("veeew").then((p) => {
          Vue.prototype.$provider = p;
          window.provider = p;
          document.title = "VEEEW";
          window.GlobalEvents.$emit('provider-resolved')
          return providerId;
        })
      case "veewar":
        await veewarTheme();
        return provider.get("veewar").then((p) => {
          Vue.prototype.$provider = p;
          window.provider = p;
          document.title = "VEEWAR";
          window.GlobalEvents.$emit('provider-resolved')
          return providerId;
        })
      default:
        await veewarTheme();
        return provider.get("veewar").then((p) => {
          Vue.prototype.$provider = p;
          window.provider = p;
          document.title = "VEEWAR";
          window.GlobalEvents.$emit('provider-resolved')
          return providerId;
        })
    }
  }

}

export const ProviderModule = getModule(ProviderModuleClass)
