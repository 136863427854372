
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import FileViewer from "@/components/FileViewer/index.vue";

@Component({
  name: 'MultipleFileViewer',
  components: {FileViewer}
})
export default class extends Vue {
  @Prop({default: "#000000"}) private arrowColor: string;
  @Prop({default: true}) private autoRotate: boolean;
  @Prop({default: false}) private autoPlay: boolean;
  @Prop({default: true}) private controls: boolean;
  @Prop({default: false}) private disabled: boolean;
  @Prop({default: false}) private plain: boolean;
  @Prop({default: "image"}) private type: string;

  @PropSync("files", { type: Array, default: () => [] }) private syncedFiles: any[];
  @PropSync("index", { type: Number, default: 0 }) private syncedIndex: number;

  public loading = false;

  // Watchers
  created(){
    this.onLoad();
  }

  // Methods
  private async onLoad() {

  }

  private scrollLeft(){
    const wrapper = document.querySelector(".multiple-file-switch .switch-wrapper");
    if (wrapper){
      wrapper.scrollLeft -= 150;
    }
  }
  private scrollRight(){
    const wrapper = document.querySelector(".multiple-file-switch .switch-wrapper");
    if (wrapper){
      wrapper.scrollLeft += 150;
    }
  }

  private next(){
    if (this.syncedIndex === this.syncedFiles.length - 1){
      this.syncedIndex = 0;
    } else {
      this.syncedIndex += 1;
    }

    const wrapper = document.querySelector(".multiple-file-switch .switch-wrapper");
    if (wrapper){
      wrapper.scrollLeft = (this.syncedIndex + 1) * 151;
    }
  }

  private prev(){
    if (this.syncedIndex === 0){
      this.syncedIndex = this.syncedFiles.length - 1;
    } else {
      this.syncedIndex -= 1;
    }

    const wrapper = document.querySelector(".multiple-file-switch .switch-wrapper");
    if (wrapper){
      wrapper.scrollLeft = (this.syncedIndex - 1) * 151;
    }
  }

  private removeFile(index: number){
    // Delete file
    this.syncedFiles.splice(index, 1)

    // Update files
    this.$emit("update:files", this.syncedFiles);

    // Set index
    if (this.syncedIndex >= this.syncedFiles.length){
      this.syncedIndex = Math.max(this.syncedFiles.length - 1, 0);
    }
  }

  private moveLeft(index?: number){
    console.log("moveLeft", index, this.syncedFiles)
    if (this.syncedFiles){
      if (index !== undefined && index > 0){
        const item = this.syncedFiles[index];
        this.syncedFiles[index] = this.syncedFiles[index - 1];
        this.syncedFiles[index-1] = item;

        // Update files
        this.$emit("update:files", this.syncedFiles);
      }
    }
  }

  private moveRight(index?: number){
    if (this.syncedFiles){
      if (index !== undefined && index >= 0 && index < (this.syncedFiles.length-1)){
        // Delete file
        const item = this.syncedFiles[index];
        this.syncedFiles[index] = this.syncedFiles[index + 1];
        this.syncedFiles[index+1] = item;

        // Update files
        this.$emit("update:files", this.syncedFiles);
      }
    }
  }

}
