
import { Component, Vue } from 'vue-property-decorator'
import ServiceWorkerUpdatePopup from '@/pwa/components/ServiceWorkerUpdatePopup.vue'
import GlobalDialogs from "@/components/GlobalDialogs/index.vue";

@Component({
  name: 'App',
  components: {
    GlobalDialogs,
    ServiceWorkerUpdatePopup,
  }
})
export default class extends Vue {

  private loaded = false;

  mounted(){

    const loading = this.$loading({
      target: "body",
      lock: true,
      background: "#fff",
    });

    // Stop loading
    window.GlobalEvents.$on('provider-resolved', () => {
      setTimeout(() => {
        this.loaded = true;
        loading.close();
      }, 500)
    })
  }

  created(){
    this.$i18n.locale = "NL-nl";
  }
}
